import books from "./books.js";
import categories from "./categories.js";

export default [
    {
        name: "books",
        module: books
    },
    {
        name: "categories",
        module: categories
    }
];
