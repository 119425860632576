<template>
  <div v-if="book" @click="showBook(book.id)" class="card book-item-wrapper">
    <div class="book--item_body">
      <div class="book__picture-container">
        <img v-if="image_book && !needDefaultImage" class="book__picture" :src="image_book" @error="needDefaultImage = true"/>

        <img
          v-else
          class="book__picture"
          src="../assets/images/book-default.jpg"
        />
      </div>

      <div class="book-control rounded-bottom">
        <p class="book-title mb-3">{{ book.title }}</p>
        <div class="book-authors mb-2">
          <div v-for="author in book.authors" :key="author.id">
            <p class="mb-3">{{ author.name }} {{ author.last_name }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BookCard",
  props: {
    book: {
      type: Object,
      required: true,
    },
    image_book: {
      //type: Object,
      required: true,
    },
  },
    data: function() {
      return {
          needDefaultImage: false,
      };
    },
  methods: {
    showBook(id) {
      if (id) {
        this.$router
            .push({ name: "book.page", params: { id } })
            .catch(() => true);
      };
    },
  },
};
</script>

<style scoped lang="scss">
.book-control {
  display: none;
  position: absolute;
  /*width: 300px;*/
  background-color: $dark;
  opacity: 0.95;
  left: 0;
  right: 0;
  top: 60%;
  bottom: 0;
  padding: 20px;
}
.book--item:hover .book-control {
  animation: fade-in 5s;
  /*transform: display 2s;*/
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //border: 1px solid $primary-light;
  border-top: unset;
}
.book-title {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: $white;
  text-align: left;
  font-weight: bold;
}
.book-authors {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: $white;
  text-align: left;
}
</style>