export default {
  app: {
    titles: {
      main: "Library",
      new: "Create book",
      edit: "Edit book",
      journal: "Journal",
      category: {
        categories: "Categories",
        edit: "Edit the category",
        create: "Create a category",
      },
      help: "Help",
    },
    pages: {
      main: "HiHub Library",
      book_create: "Create book",
      book_edit: "Edit book",
      journal: "Journal",
    },
    actions: {
      save: "Save",
      cancel: "Cancel",
      upload: "Upload",
      delete: "Delete",
    },
    errors: {
      field_required: "This field is required",
      field_length1: "Required length is from ",
      field_length2: " to ",
      field_length3: " characters",
    },
    messages: {
      error: {
        header: "Error",
        file_size: "Maximum file size is ",
        image_size: "Maximum image size is ",
        mb: " Mb. ",
        server_error: "Server error",
        error_occurred: "An error occurred"
      },
      category_created: "You have created a category!",
      category_create_failed: "Failed to create category",
      category_edited: "Category has been edited!",
      category_edit_failed: "Failed to edit category"
    },
  },
  book: {
    books: "Books",
    no_books: "There are no books in this space yet",
    fields: {
      title: "Title",
      author: "Author",
      authors: "Authors",
      description: "Description",
    },
    actions: {
      create: "Create book",
      edit: "Edit book",
      delete: "Delete book",
      add_author: "Add author",
      add_exemplar: "Add exemplar",
      delete_exemplar: "Delete exemplar",
      take: "Take book",
      give_back: "Give back book",
      not_available: "Not available",
      download: "Download book",
      readed: "Already read",
      show_more: "Show more",
    },
    statuses: {
      wanted: "Want to read",
      reading: "Reading",
      readed: "Already read",
      new: "New",
      back: "Returned",
    },
    editor: {
      placeholder: {
        title: "Enter book's title",
        author_name: "Enter author's name",
        author_last_name: "Enter author's lastname",
        description: "Enter description",
      },
    },
  },
  exemplar: {
    fields: {
      image: "Book cover",
      type: "Type",
      pages: "Page Count",
      year: "Publish year",
      isbn: "ISBN",
      file: "File",
      publishing: "Publisher",
    },
    types: {
      paper: "Paper",
      electro: "eBook",
      audio: "Audiobook",
    },
    editor: {
      placeholder: {
        pages: "Enter page count",
        year: "YYYY",
        isbn: "Enter book's ISBN",
        publishing: "Enter publisher",
        category: "Select category",
      },
      upload: {
        image: "Upload book cover",
        image_update: "Replace book cover",
        file: "Upload book file",
        file_update: "Replace book file",
      },
      preview: "Preview",
    },
  },
  journal: {
    fields: {
      date: "Date",
      book_title: "Book title",
      authors: "Authors",
      reader: "Reader",
      status: "Stetus",
    },
    statuses: {
      wanted: "Wanted",
      reading: "Checked out",
      readed: "Returned",
      new: "New",
    },
  },
  category: {
    categories: "Categories",
    category: "Category",
    create: "Create category",
    create_button: "Create category",
    edit: "Edit category",
    title: "Title",
    title_placeholder: "Enter the title of the category",
    parent: "Parent category",
    parent_placeholder: "Select a parent category",
    no_found: "Category not found",
    description: "Category description",
    description_placeholder: "Enter category description",
    delete: "Deleting the category",
    confirm_delete: "Are you sure that you want to delete the category with nested sections and books? ",
    has_no_books: "No books found in this category",
    no_categories: "There are no partitions in this space yet",
  }
};
