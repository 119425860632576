/**
 * Routes of service
 *
 * All routes must be use layout component on first level (with path "/")
 * Root of service index component must have redirect to service index page (it may any name - not only index)
 */

export default [
    {
        path: "/",
        redirect: { name: "library.index" },
        name: "root",
        components: {
            default: () => import("@/view/layout/Layout"),
        },
        children: [
            {
                path: "/help",
                component: () => import("./views/Help.vue"),
                name: "help"
            },
            {
                path: "/",
                components: {
                    default: () => import("./layouts/LibraryLayout"),
                    "title-actions": () => import("./components/ActionsButton.vue"),
                    search: () => import("./components/Searchbar"),
                },
                children: [
                    {
                        path: "/",
                        name: "library.index",
                        component: () => import("./views/Main.vue")
                    },
                    {
                        path: "/book/new",
                        name: "book.new",
                        component: () => import("./views/BookEdit.vue"),
                        props: true
                    },
                    {
                        path: "/book/edit/:id",
                        name: "book.edit",
                        component: () => import("./views/BookEdit.vue"),
                        props: true
                    },
                    {
                        path: "/book/:id",
                        name: "book.page",
                        component: () => import("./views/BookPage.vue"),
                        props: true
                    },
                    {
                        path: "categories",
                        name: "category.index",
                        component: () => import("./views/CategoryIndex")
                    },
                    {
                        path: "category/new",
                        name: "category.new",
                        component: () => import("./views/CategoryEdit"),
                        props: true
                    },
                    {
                        path: "category/edit/:id",
                        name: "category.edit",
                        component: () => import("./views/CategoryEdit"),
                        props: true
                    },
                    {
                        path: "category/:id",
                        name: "category.show",
                        component: () => import("./views/CategoryShow"),
                    },

/*                   {
                        path: "/journal",
                        name: "admin.journal",
                        component: () => import('./views/Journal.vue'),
                    },*/
                ]
            }
        ]
    }
];
