<template>
  <div class="page-spinner">
    <b-spinner variant="primary" label="Spinning"></b-spinner>
  </div>
</template>

<script>
export default {
  name: "PageSpinner",
};
</script>

<style scoped>
.page-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
}
</style>
