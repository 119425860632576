export default class ApiRequests {}

ApiRequests.book = {
    allBooks: ({ client }, data) => client.post("/api/library/books", data),
    oneBook: ({ client }, id) => client.get("/api/library/book/" + id),
    booksByCategory: ({ client }, data) => client.get(
        "/api/library/category/" + data.categoryId + "/books/" + data.userId + "?page=" + data.page
    ),

    createBook: ({ client }, data) => client.post("/api/library/book/store", data),
    updateBook: ({ client }, id, data) => client.post("/api/library/book/" + id, data),
    deleteBook: ({ client }, id) => client.post("/api/library/book/" + id),

    takeExemplar: ({ client }, exemplar_id, user_id) =>
        client.post("/api/library/exemplar/take", {
            exemplar_id: exemplar_id,
            user_id: user_id
        }),
    giveBackExemplar: ({ client }, exemplar_id, user_id) =>
        client.post("/api/library/exemplar/giveBack", {
            exemplar_id: exemplar_id,
            user_id: user_id
        }),
    changeUserStatus: ({ client }, exemplar_id, user_id, new_status) =>
        client.post("/api/library/exemplar/changeUserStatus", {
            exemplar_id: exemplar_id,
            user_id: user_id,
            new_status: new_status
        }),

    createExemplar: ({ client }, data) => client.post("/api/library/exemplar/store", data),
    updateExemplar: ({ client }, id, data) => client.post("/api/library/exemplar/edit/" + id, data),
    deleteExemplar: ({ client }, id) => client.post("/api/library/exemplar/delete/" + id),

    loadJournalData: ({ client }, data) => client.get("/api/library/journal", { params: data })
};
ApiRequests.libCategory = {
    get: ({ client }, id) => client.get("/api/library/category/" + id),
    list: ({ client }) => client.get("/api/library/categories"),
    save: ({ client }, category) => category.id ?
        client.post("/api/library/category/edit/" + category.id, category) :
        client.post("/api/library/category/store", category),
    delete: ({ client }, id) => client.delete("/api/library/category/" + id),
};
ApiRequests.libMenu = {
    loadChildrenOptions: ({ client }, id) => client.get("/api/library/category/load-for-menu/" + id),
    buildAncestorsTree: ({ client }, id) =>
        client.get("/api/library/category/build-ancestors-tree/" + id)
};

ApiRequests.search = {
    full: ({ client }, search) =>
      client.get(encodeURI("/api/library/search?per_page=3&search=" + search))
};

