import Vue from "vue";

const books = {
  namespaced: true,
  state: {
    mainPagePaginator: {
      "last_page": null,
      "current_page": 0,
      "per_page": 10,
      "total": 0,
    },
    allBooks: [],
    allBooksLoaded: false,
    journal: [],
    loading: false,
    listByCategory: {
/*       0: {
        lastPage: false,
        currentPage: 1,
        list: []
      } */
    }
  },
  getters: {
    paginator(state) {
      return state.mainPagePaginator;
    },
    getAllBooks(state) {
      return state.allBooks.length > 0 ? state.allBooks : null;
    },
    getAllBooksLoaded(state) {
      return state.allBooksLoaded;
    },
    getBook(state) {
      return (id) => {
        const index = state.allBooks.findIndex(book => book.id == id);
        return state.allBooks[index] || null;
      };
    },
    getJournal: (state) => state.journal,
    listByCategoryId: (state) => category_id => state.listByCategory[category_id]
  },

  mutations: {
    setBook(state, { id, data }) {
      const index = state.allBooks.findIndex(book => book.id == id);
      let newArr = [];
      newArr = state.allBooks;
      if(index > -1) {
        newArr[index] = data;
      } else {
        newArr.push(data);  
      }
      Vue.set(state, "allBooks", newArr);
    },
    createExemplar(state, { book_id, data }) {
      const index = state.allBooks.findIndex(book => book.id == book_id);
      Vue.set(state.allBooks[index]["exemplars"], data.id, data);
    },
    unsetBook(state, id) {
      const index = state.allBooks.findIndex(book => book.id == id);
      Vue.delete(state.allBooks, index);
    },
    unsetExemplar(state, { book_id, exemplar_id }) {
      const index = state.allBooks.findIndex(book => book.id == book_id);
      Vue.delete(state.allBooks[index]["exemplars"], exemplar_id);
    },
    setUserStatusBook(state, { id, userStatus }) {
      const index = state.allBooks.findIndex(book => book.id == id);
      Vue.set(state.allBooks[index], "user_status", userStatus);
    },
    setAvailableBook(state, { id, available }) {
      const index = state.allBooks.findIndex(book => book.id == id);
      Vue.set(state.allBooks[index], "available", available);
    },
    setJournal(state, { data }) {
      state.journal = data;
    },
    addBookToCategoryList(state, data) {
      if (!state.listByCategory[data.category_id]) {
        state.listByCategory[data.category_id] = {
          currentPage: data.meta.current_page,
          list: [],
          lastPage: false
        };
      }
      const categoryData = state.listByCategory[data.category_id];
      data.data.forEach(book => {
        if (!categoryData.list.find(subBook => Number(subBook.id) === Number(book.id)))
          categoryData.list.push(book);
      });
      if (Number(data.meta.current_page) < Number(data.meta.last_page)) {
        categoryData.lastPage = false;
        categoryData.currentPage++;
      } else if (Number(data.meta.current_page) === Number(data.meta.last_page)) {
        categoryData.lastPage = true;
      }
    },
    setAllBooksLoaded(state, loaded) {
      Vue.set(state, "allBooksLoaded", loaded);
    }
  },

  actions: {
    loadAllBooks({ commit, getters, state }) {
      return new Promise((resolve, reject) => {
        let curPage = state.mainPagePaginator.current_page != state.mainPagePaginator.last_page ? 
          state.mainPagePaginator.current_page + 1 : state.mainPagePaginator.current_page;
        this.$app.$api.book
          .allBooks({
            per_page: state.mainPagePaginator.per_page,
            cur_page: curPage
          })
          .then((response) => {
            state.mainPagePaginator.current_page = response.data.cur_page;
            state.mainPagePaginator.last_page = response.data.last_page;

            Object.values(response.data.data).forEach((book) => {
              commit("setBook", { id: book.id, data: book });
            });
            commit("setAllBooksLoaded", true);
            resolve(state.allBooks);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    loadBook({ commit, getters, dispatch }, { id }) {
      if (!id) {
        return { loaded: false };
      }
      return new Promise((resolve, reject) => {
        if (getters.getBook(id) !== null) {
          resolve(getters.getBook(id));
          return;
        }
        this.$app.$api.book
          .oneBook(id)
          .then(({ data }) => {
            commit("setBook", { id: id, data: data.data });
            resolve(data.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async saveBook({ commit, dispatch, state }, { formData, book }) {
      let bookLoad = book;

      if (formData === null) formData = new FormData();
      Object.keys(bookLoad).forEach((k) => {
        if (Array.isArray(bookLoad[k])) {
          formData.set(k, JSON.stringify(bookLoad[k]));
        } else if (bookLoad[k]) {
          formData.set(k, bookLoad[k]);
        }
      });

      if (bookLoad.year) {
        let bookYear = new Date(bookLoad.year, 1).toUTCString();
        formData.set("year", bookYear);
      }
      let response;
      if (bookLoad.id) {
        formData.append("_method", "PUT");
        response = await this.$app.$api.book.updateBook(book.id, formData);
        const index = state.allBooks.findIndex(book => book.id == book.id);
        let oldBook = state.allBooks[index];
        if (oldBook.category_id !== response.data.data.category_id) {
          if (response.data.data.category_id)
            commit("categories/INCREMENT_BOOKS_COUNTER_CATEGORY", response.data.data.category_id, { root: true });
          if (oldBook.category_id)
            commit("categories/DECREMENT_BOOKS_COUNTER_CATEGORY", oldBook.category_id, { root: true });
        }
        commit("setBook", { id: book.id, data: response.data.data });
      } else {
        response = await this.$app.$api.book.createBook(formData);
        if (response.data.data.category_id) {
          const categoryData = state.listByCategory[response.data.data.category_id];
            if (!categoryData?.list.find(elem => Number(elem.id) === Number(book.id))) {
              categoryData.list.unshift(book);
            }
            commit("categories/INCREMENT_BOOKS_COUNTER_CATEGORY", response.data.data.category_id, { root: true });
        }
        commit("setBook", { id: response.data.data.id, data: response.data.data });
      }
      return response;
    },
    deleteBook({ commit, dispatch }, book_id) {
      return this.$app.$api.book.deleteBook(book_id).then((response) => {
        commit("unsetBook", book_id);
      });
    },
    takeBook({ commit, dispatch }, { book_id, exemplar_id, user_id }) {
      if (!user_id) return { loaded: false };
      return this.$app.$api.book
        .takeExemplar(exemplar_id, user_id)
        .then((response) => {
          commit("setUserStatusBook", {
            id: book_id,
            userStatus: response.data.status,
          });
          commit("setAvailableBook", { id: book_id, available: false });
        });
    },
    giveBackBook({ commit, dispatch }, { book_id, exemplar_id, user_id }) {
      if (!user_id) return { loaded: false };
      return this.$app.$api.book
        .giveBackExemplar(exemplar_id, user_id)
        .then((response) => {
          commit("setUserStatusBook", {
            id: book_id,
            userStatus: response.data.status,
          });
          commit("setAvailableBook", { id: book_id, available: true });
        });
    },
    changeUserStatus(
      { commit, dispatch },
      { book_id, exemplar_id, user_id, new_status }
    ) {
      if (!user_id) return { loaded: false };
      return this.$app.$api.book
        .changeUserStatus(exemplar_id, user_id, new_status)
        .then((response) => {
          commit("setUserStatusBook", { id: book_id, userStatus: new_status });
        });
    },
    loadJournalData({ commit }) {
      return this.$app.$api.book.loadJournalData().then((response) => {
        commit("setJournal", { data: response.data });
        return response.data;
      });
    },
    loadListByCategory(context, data) {
      data.page = context.state.listByCategory[data.categoryId]?.currentPage || 1;
      let request = this.$app.$api.book.booksByCategory(data);
      request.then(response => {
        const payload = response.data;
        payload.category_id = data.categoryId;
        context.commit("addBookToCategoryList", payload);
        return true;
      });
      return request;
    },
  },
};

export default books;
