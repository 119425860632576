export default {
  app: {
    titles: {
      main: "Библиотека",
      new: "Добавление книги",
      edit: "Редактирование книги",
      journal: "Журнал",
      category: {
        index: "Разделы",
        edit: "Редактирование категории",
        create: "Создание категории",
      },
      help: "Помощь",
    },
    pages: {
      main: "HiHub Библиотека",
      book_create: "Добавление книги",
      book_edit: "Редактирование информации о книге",
      journal: "Журнал выдачи книг",
    },
    actions: {
      save: "Сохранить",
      cancel: "Отменить",
      upload: "Загрузить",
      delete: "Удалить",
      show_more: "Показать ещё",
    },
    errors: {
      field_required: "Это поле обязательное",
      field_length1: "Длина поля - от ",
      field_length2: " до ",
      field_length3: " символов",
    },
    messages: {
      error: {
        header: "Ошибка",
        file_size: "Размер файла не должен превышать ",
        image_size: "Размер изображения не должен превышать ",
        mb: " Мб. ",
        server_error: "Произошла ошибка на сервере",
        error_occurred: "Произошла ошибка",
      },
      category_created: "Вы создали раздел!",
      category_create_failed: "Не удалось создать раздел",
      category_edited: "Раздел был отредактирован!",
      category_edit_failed: "Не удалось отредактировать раздел"
    },
  },
  book: {
    books: "Книги",
    no_books: "В этом пространстве еще нет книг",
    fields: {
      title: "Название книги",
      author: "Автор",
      authors: "Авторы",
      description: "Описание",
    },
    actions: {
      create: "Добавить книгу",
      edit: "Редактировать",
      delete: "Удалить книгу",
      add_author: "Добавить автора",
      add_exemplar: "Добавить экземпляр",
      delete_exemplar: "Удалить экземпляр",
      take: "Взять книгу",
      give_back: "Вернуть книгу",
      not_available: "Нет в наличии",
      download: "Скачать книгу",
      readed: "Я прочитал!",
      show_more: "Показать ещё",
    },
    statuses: {
      wanted: "Хочу прочитать",
      reading: "Читаю",
      readed: "Прочитано",
      new: "Новая",
      back: "Вернул",
    },
    editor: {
      placeholder: {
        title: "Введите название книги",
        author_name: "Введите имя автора",
        author_last_name: "Введите фамилию автора",
        description: "Введите описание книги",
        category: "Выберите раздел",
      },
    },
  },
  exemplar: {
    fields: {
      image: "Обложка",
      type: "Тип",
      pages: "Количество страниц",
      year: "Год издания",
      isbn: "ISBN",
      file: "Файл",
      publishing: "Издательство",
    },
    types: {
      paper: "Бумажная",
      electro: "Электронная",
      audio: "Аудиокнига",
    },
    editor: {
      placeholder: {
        pages: "Введите количество",
        year: "ГГГГ",
        isbn: "Введите ISBN",
        publishing: "Введите название",
      },
      upload: {
        image: "Загрузить обложку",
        image_update: "Заменить обложку",
        file: "Загрузить файл",
        file_update: "Заменить файл",
      },
      preview: "Предпросмотр",
    },
  },
  journal: {
    fields: {
      date: "Дата",
      book_title: "Название книги",
      authors: "Авторы",
      reader: "Читатель",
      status: "Статус",
    },
    statuses: {
      wanted: "Ожидание",
      reading: "Выдана",
      readed: "Возвращена",
      new: "Новая",
    },
  },
  category: {
    categories: "Разделы",
    category: "Раздел",
    create: "Создание раздела",
    create_button: "Добавить раздел",
    edit: "Редактировать раздел",
    title: "Название",
    title_placeholder: "Введите название раздела",
    parent: "Родительский раздел",
    parent_placeholder: "Выберите родительский раздел",
    no_found: "Раздел не найден",
    description: "Описание раздела",
    description_placeholder: "Введите описание раздела",
    delete: "Удаление категории",
    confirm_delete: "Вы уверены, что хотите удалить категорию вместе со всем содержимым?",
    has_no_books: "Не найдено книг в данном разделе",
    no_categories: "В этом пространстве еще нет разделов",
  }
};
