import Vue from "vue";

const categories = {
  namespaced: true,
  state: {
    current: null,
    list: [],
    listLoaded: false,
    listProperties: {
      lastPage: false,
      currentPage: 1,
      loaded: false,
    },
    children: [],
  },
  getters: {
    categoriesLoaded: (state) => state.listLoaded,
    currentCategory: (state) => state.current,
    byId: (state) => (id) => state.list.find((elem) => elem.id == id),
    listOfCategories: (state) => state.list,
    propOfListOfCategories: (state) => state.listProperties,
  },

  mutations: {
    ADD_CATEGORY_TO_LIST(state, category) {
      //for updating
      let parent = state.list.find(
        (elem) => elem.children && elem.children.includes(category.id)
      );
      if (parent) {
        parent.children = parent.children.filter((elem) => elem != category.id);
        if (!parent.children.length) parent.has_children = false;
      }
      state.list = state.list.filter((elem) => elem.id != category.id);

      // for both
      if (state.list.length) {
        let parent_id = category.parent_id || 0;
        if (!state.list.find((elem) => elem.id == parent_id).children) {
          state.list.find((elem) => elem.id == parent_id).children = [];
          state.list.find((elem) => elem.id == parent_id).has_children = true;
        }
        state.list
          .find((elem) => elem.id == parent_id)
          .children.push(category.id);
      }
      state.list.unshift(category);
    },
    REMOVE_CATEGORY(state, id) {
      let parent = state.list.find(
        (elem) => elem.children && elem.children.includes(id)
      );
      if (parent) {
        parent.children = parent.children.filter((elem) => elem != id);
      }
      state.list = state.list.filter((elem) => elem.id != id);
    },
    SAVE_CURRENT(state, loaded) {
      state.current = loaded;
    },
    SAVE_CATEGORIES_LIST(state, data) {
      state.list = data;
      state.listProperties.loaded = true;
    },
    SET_LIST_LOADED(state, status = true) {
      state.listLoaded = status;
    },
    INCREMENT_BOOKS_COUNTER_CATEGORY(state, id) {
/*      let category = state.list.find(
          category => category.id == id
      );
      if (category) category.books_count++;*/

      let old_books_count = state.list.find((elem) => elem.id == id).books_count;
      Vue.set(
          state.list.find((elem) => elem.id == id),
          "books_count",
          old_books_count + 1
      );
    },
    DECREMENT_BOOKS_COUNTER_CATEGORY(state, id) {
      let old_books_count = state.list.find((elem) => elem.id == id).books_count;
      Vue.set(
          state.list.find((elem) => elem.id == id),
          "books_count",
          old_books_count - 1
      );
    }
  },
  actions: {
    loadCurrent(context, id) {
      let request = this.$app.$api.libCategory.get(id);
      request.then((response) => {
        context.commit("SAVE_CURRENT", response.data.data);
      });
      return request;
    },
    loadById(context, id) {
      let request = this.$app.$api.libCategory.get(id);
      return request;
    },
    loadCategoriesList(context) {
      context.commit("SET_LIST_LOADED", false);
      let request = this.$app.$api.libCategory.list();
      request
        .then((response) => {
          context.dispatch("initTreeCategories", response.data.data);
        })
        .finally(() => {
          context.commit("SET_LIST_LOADED", true);
        });
      return request;
    },
    save(context, category) {
      let formData = new FormData();

      Object.keys(category).forEach((k) => {
        if (category[k])
          formData.set(k, category[k]);
      });

      let request = this.$app.$api.libCategory.save(category);
      request.then((response) => {
        context.commit("ADD_CATEGORY_TO_LIST", response.data.data);
      });
      return request;
    },
    delete(context, categoryId) {
      context.state.children = [];

      let children = helpers.getIds(context.state.list, categoryId);
      let request = this.$app.$api.libCategory.delete(categoryId);
      request.then(() => {
        if (children.length) {
          children.forEach((id) => {
            context.dispatch("idea/removeByCategoryId", id, { root: true });
            context.commit("REMOVE_CATEGORY", id);
          });
        }
        context.dispatch("idea/removeByCategoryId", categoryId, { root: true });
        context.commit("REMOVE_CATEGORY", categoryId);
      });
    },

    initTreeCategories({ commit }, categories) {
      let rootCategories = [];
      categories.forEach((elem) => {
        let parentId = parseInt(elem.parent_id) || 0;
        if (parentId > 0) {
          if (!categories.find((elem) => elem.id == parentId).children) {
            categories.find((elem) => elem.id == parentId).children = [];
          }
          categories
            .find((parent) => parent.id == parentId)
            .children.push(elem.id);
        } else {
          rootCategories.push(elem.id);
        }
      });

      // root categories and ideas
      categories.push({ id: 0 });
      if (rootCategories.length > 0) {
        categories.find((elem) => elem.id === 0).children = rootCategories;
      }
      commit("SAVE_CATEGORIES_LIST", categories);
    },
  },
};
const helpers = {
  children: [],
  getIds(list, id) {
    this.getChildrenIds(list, [parseInt(id)]);
    return this.children;
  },
  getChildrenIds(list, ids) {
    let children = [];
    list.forEach((category) => {
      if (ids.includes(category.parent_id)) {
        children.push(category.id);
      }
    });
    if (children.length) {
      this.children = this.children.concat(children);
      this.getChildrenIds(list, children);
    }
  },
};

export default categories;
